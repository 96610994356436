import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';

import Navbar from './Navbar'

import '../all.sass';

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet>
      <title>Newsletter Guide.org</title>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <Navbar />
    <Container>
      <Row>
        <Col>
          <div>{children}</div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default TemplateWrapper
