import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { 
  Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarToggler, Collapse,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import github from '../img/github-icon.svg';
import logo from '../img/newsletterguide-logo-full.svg';

const SiteNavbar = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPage(sort: { fields: wordpress_id }, limit: 5) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <Navbar color="light" light expand="md" className="mb-5">
        <NavbarBrand href="/">
          <img 
            src={logo}
            style={{ width: 300, maxWidth: '100%' }}
            alt="Newsletter Guide.org"
            className="d-inline-block align-top d-none"
          />
        </NavbarBrand>
        {/* <NavbarToggler  />
        <Collapse isOpen navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/templates/">
                Templates
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://github.com/reactstrap/reactstrap">
                <img
                  src={github}
                  style={{ width: 20 }}
                  alt="Github"
                  className="d-inline-block align-top"
                />
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse> */}
      </Navbar>
    )}
  />
)
  
    


      // // <Navbar className="mb-4">
      // //   <NavbarBrand>
      // //     <img 
      // //       src={logo}
      // //       style={{ width: 400, maxWidth: '100%' }}
      // //       alt="Newsletter Guide.org"
      // //       className="d-inline-block align-top d-none"
      // //     />
      // //   </NavbarBrand>
      // //   <NavbarToggler className="mr-2" />
      // //   <Collapse isOpen navbar>
      // //     <Nav className="ml-auto" navbar>
      // //       <NavItem>
      // //         <NavLink href="/components/">Components</NavLink>
      // //       </NavItem>
      // //       <NavItem>
      // //         <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
      // //       </NavItem>
      // //       <UncontrolledDropdown nav inNavbar>
      // //         <DropdownToggle nav caret>
      // //             Options
      // //         </DropdownToggle>
      // //         <DropdownMenu right>
      // //           <DropdownItem>
      // //               Option 1
      // //           </DropdownItem>
      // //           <DropdownItem>
      // //               Option 2
      // //           </DropdownItem>
      // //           <DropdownItem divider />
      // //           <DropdownItem>
      // //               Reset
      // //           </DropdownItem>
      // //         </DropdownMenu>
      // //       </UncontrolledDropdown>
      // //     </Nav>
      // //   </Collapse>
      //   {/* <Nav className="ml-auto" navbar>
      //     <NavItem>
      //       <NavLink href="#">
      //         Test
      //       </NavLink>
      //     </NavItem>
      //     <NavItem>
      //       <NavLink href="#">
      //         <img
      //           src={github}
      //           style={{ width: 20 }}
      //           alt="Github"
      //           className="d-inline-block align-top"
      //         />
      //       </NavLink>
      //     </NavItem>
      //   </Nav> */}
      // // </Navbar>

//     render={data => (
//       <nav className="navbar is-transparent">
//         <div className="container">
//           <div className="navbar-brand">
//             <Link to="/" className="navbar-item">
//               <figure className="image">
//                 <img src={logo} style={{ width: '400px', height: '100px' }} />
//               </figure>
//             </Link>
//           </div>
//           <div className="navbar-start">
//             {data.allWordpressPage.edges.map(edge => (
//               <Link className="navbar-item" to={edge.node.slug}>
//                 {edge.node.title}
//                 {' '}
// foo
//               </Link>
//             ))}
//           </div>
//           <div className="navbar-end">
//             <a
//               className="navbar-item"
//               href="https://github.com/GatsbyCentral/gatsby-starter-wordpress"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <span className="icon">
//                 <img src={github} alt="Github" />
//               </span>
//             </a>
//           </div>
//         </div>
//       </nav>
//     )}

export default SiteNavbar;
